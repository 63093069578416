import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/main.scss';
import App from './App';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

const host = window.location.host;
const http = window.location.protocol;
let a = host;
if(host.includes('localhost'))  a = '127.0.0.1:8000';
export const STORAGEURL = host.includes('localhost') ? "http://127.0.0.1:8000" : "";
export const APIURL = http+"//"+a+"/api";

const root = ReactDOM.createRoot(document.body);
root.render(
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<App />} />
      <Route exact path="/:translation" element={<App />} />
    </Routes>
  </BrowserRouter>
);
