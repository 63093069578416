import parse from 'html-react-parser';
import { useContext } from 'react';
import { AppContext } from '../App';
import { STORAGEURL } from '..';
import Modal from './UI/Modal';
import { useState } from 'react';
import ReactPlayer from 'react-player';


const Uvod = () => {
    const {h1, svg,perex,intropic,introvideo, readmore, boxes, language } = useContext(AppContext);
    const [modalText, setModalText] = useState(false);
    const [modalVideo, setModalVideo] = useState(false);
    return (
        <section className="bg-[#F6F6F6]">
            <div id='intro' className="bg-base-color introbg text-white min-h-[90vh] w-full py-10 tablet:pb-0 tablet:pt-24 px-2vw flex flex-col tablet:flex-col-reverse justify-center gap-8">
                <img src={STORAGEURL+intropic} style={introvideo&&{cursor:'pointer'}} onClick={()=>setModalVideo(true)} className='absolute right-vw bottom-0 w-1/2 max-h-[90%] object-contain tablet:w-full tablet:relative tablet:right-0' />
                <button className='hidden tablet:block' onClick={()=>setModalText(true)}>{readmore}</button>
                <h1 className="text-5xl w-2xl max-w-[50%] leading-tight tablet:text-4xl tablet:max-w-full">{parse(h1)}
                    <div className={'max-w-[80vw] '+(language==='cz'?'h-[120px] -mt-[30px]':'h-[55px] -mt-[10px]')}>{parse(svg)}</div>
                </h1>
                <div className="w-xl parsedDiv !max-w-[50%] tablet:hidden">{parse(perex)}</div>
            </div>
            <Modal state={[modalText, setModalText]}>
                <div className='parsedDiv'>{parse(perex)}</div>
            </Modal>
            {introvideo&&<Modal state={[modalVideo, setModalVideo]} className='uvodVideo !min-w-[70vw]'>
                <ReactPlayer url={introvideo} />
            </Modal>}
            <div className="px-vw pb-8 grid grid-cols-3 tablet:grid-cols-1 gap-4">
                {boxes?.map(box=>
                        <div className="bg-[#F6F6F6] p-8 rounded-3xl shadow-xl -translate-y-10" key={box.id}>
                            <img src={STORAGEURL+box.icon} alt='Icon' />
                            <h3 className='py-4'>{box.name}</h3>
                            <div className="parsedDiv">{parse(box.text)}</div>
                        </div>
                    )}
            </div>
        </section>
    )
}

export default Uvod