import Footer from "./components/Footer";
import Header from "./components/Header/Header"
import Navigation from "./components/Header/Navigation"
import { ReactLenis, useLenis} from '@studio-freight/react-lenis';
import logo from "./assets/svg/logo.svg"
import logo_en from "./assets/svg/logo_en.svg"
import { useEffect, useState } from "react";
import {initCookieConsent, showCookieConsent} from "./components/UI/Cookies";
import { ToastProvider } from "./components/UI/useToast";
import HashScroll from "./components/UI/HashScroll";
import Dropdown from "./components/Form/Dropdown";
import Uvod from "./components/Uvod";
import Programy from "./components/Programy";
import ZeptejSe from "./components/ZeptejSe";
import Tajemnice from "./components/Tajemnice";
import Grid from "./components/Grid";
import Prihlaska from "./components/Prihlaska";
import { createContext } from "react";
import axios from 'axios';
import { APIURL } from ".";
import { useNavigate, useParams } from "react-router-dom";
import GallerySwiper from "./components/GallerySwiper";
import EnVideo from "./components/EnVideo";
import parse from 'html-react-parser';

export const AppContext = createContext();

const App = () => {
  const {translation} = useParams();
  const [data, setData] = useState();
  const [header, setHeader] = useState('');
  const [soc, setSoc] = useState();
  const lenis = useLenis();
  const navigate = useNavigate();
  const lang = process.env.REACT_APP_LANGUAGE;
  // const scroll = (hash) => {
  //   lenis.scrollTo(document.getElementById(hash));
  // }

  const [tab,setTab] = useState('bc');

  const langArray = [
    {value:'en', label:'EN'},
    {value:'de', label:'DE'},
    {value:'es', label:'ES'}
  ]

  const showConsent = () => {
    showCookieConsent();
}

  const getPageData = async () => {
    let preklad = translation?translation:lang==='cz'?'cs':'en';
    await axios.post(APIURL+'/page', {name: lang==='cz'?'hp':'hp_en', language: preklad}).then(async response =>{
      await axios.post(APIURL+'/collection/items', {type: 'boxes_'+lang, orderAsc: 'ASC', limit: 100, page: 1,language: preklad}).then(resp =>{
        setData({...response.data.body, boxes: resp.data.body.items, language:lang, translation: preklad});
      })
    }).catch(()=>{
      navigate('/')
    })
  }
  const getSoc = async () => {
    await axios.post(APIURL+'/collection/items', {type: 'socials', orderAsc: 'ASC', limit: 100, page: 1,language: 'cs'}).then(response =>{
      setSoc(response.data.body.items);
    })
  }

  useEffect(()=>{
    if (process.env.NODE_ENV === 'production') {
      initCookieConsent();
    }
    getSoc()
  }, [])

  useEffect(()=>{
    lenis?.on('scroll', (e) => {
      if (e.actualScroll > (document.getElementById('intro')?.scrollHeight*0.75)) {
        setHeader('invert')
      } else {
        setHeader('')
      }
    })
}, [lenis])

  useEffect(()=>{
    getPageData();
  }, [lang, translation])

  if (data) return (
    <ReactLenis root>
      <ToastProvider>
        <AppContext.Provider value={{...data, tab, setTab}}>
          <Header fixed className={header} >
            <img id='logo' src={lang==='cz'?logo:logo_en} alt="FMV logo" />
            <Navigation gap='2rem' moveRight>
              {lang==='cz'?<a href={data.header_url} target="_blank" className="button black">
                {window.innerWidth>414?"Podat přihlášku":"Přihláška"}
                </a>:<a href={data.apply_url} target="_blank" className="button black">
                {data.apply_text}</a>}
              {lang==='en'&&<Dropdown name='lang' options={langArray} defaultValue={langArray.find(l=>l.value===translation?translation:'en')} onChange={({value})=>navigate(value==='en'?'/':('/'+value))} />}
            </Navigation>
          </Header>
          
          <main>
            <Uvod />
            <Programy />
            <ZeptejSe />
            <Tajemnice />
            {lang==='cz'&&<GallerySwiper />}
            <div className="gradienttprosim">
              {lang==='cz'?<Grid />:<EnVideo />}
            </div>
            <Prihlaska />
          </main>
          <footer>
            <div className="footer">
                  <div className="footer__left">
                      <img src={lang==='cz'?logo:logo_en} alt="FMV logo" />
                  </div>
                  <div className='footer__right'>
                    <div className='parsedDiv !pt-0'>
                        {parse(data.address)}
                    </div>
                    <div>
                        <div className='icons pb-4'>
                            {soc?.map(s=><a key={s.id} href={s.url} target="_blank">{parse(s.icon)}</a>)}
                        </div>
                        <span onClick={showConsent}>{data.cookies||'Nastavení soukromí'}</span>
                    </div>
                  </div>
              </div>
              {/* <div className="copyright">Copyright © {new Date().getFullYear()} Vysoká škola ekonomická v Praze</div> */}
          </footer>
          <HashScroll />
        </AppContext.Provider>
      </ToastProvider>
    </ReactLenis>
  );
}

export default App;
